export const TourData = [
  {
    src: "images/getyourguide/dance.jpg",
    text: "Dance and Kimono",
    path: "https://www.getyourguide.com/tokyo-l193/tokyo-authentic-japanese-dance-class-kimono-souvenirs-t731232/",
    color: "rgb(9, 59, 34)",
    textcolor: "white",
  },
  {
    src: "images/getyourguide/tsukiji.jpg",
    text: "Tsukiji Tour",
    path: "https://www.getyourguide.com/tokyo-l193/tokyo-tsukiji-fish-market-foodie-and-educational-tour-t803880",
    color: "rgb(9, 59, 34)",
    textcolor: "white",
  },
  {
    src: "images/getyourguide/sushi.jpg",
    text: "Downtown Tour",
    path: "https://www.getyourguide.com/tokyo-l193/downtown-tour-sushi-experience-edo-museum-garden-tour-t740227",
    color: "rgb(9, 59, 34)",
    textcolor: "white",
  },
  {
    src: "images/culture/IMG_4544.jpg",
    text: "Cultural Experience",
    path: "/tours/culture",
    color: "rgb(9, 59, 34)",
    textcolor: "white",
  },
  {
    src: "images/sumo/PHOTO-2023-12-03-12-14-16_2.jpg",
    text: "Sumo Experience",
    path: "/tours/sumo-experience",
    color: "rgb(9, 59, 34)",
    textcolor: "white",
  },
  {
    src: "images/custom/IMG_1369.jpg",
    text: "Customized Tours",
    path: "/tours/custom",
    color: "rgb(9, 59, 34)",
    textcolor: "white",
  },
  // {
  //   src: "images/sumo.jpg",
  //   text: "Sumo Morning",
  //   path: "/tours/sumo-morning",
  //   color: "rgb(9, 59, 34)",
  //   textcolor: "white",
  // },

  // {
  //   src: "images/downtown/IMG_8510.jpg",
  //   text: "Downtown Tour",
  //   path: "/tours/downtown-tour",
  //   color: "rgb(9, 59, 34)",
  //   textcolor: "white",
  // },
  {
    src: "images/farming/IMG_2805.jpg",
    text: "Farming Experience",
    path: "/tours/farming",
    color: "rgb(9, 59, 34)",
    textcolor: "white",
  },
];
